<template>
  <div class="page">
    <div class="video_box">
      <a-carousel arrows class="swiper-pc">
        <div
          slot="prevArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="left: 10px; zindex: 1"
        >
          <a-icon type="left-circle" />
        </div>
        <div
          slot="nextArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="right: 10px"
        >
          <a-icon type="right-circle" />
        </div>
        <div class="video_wra">
          <video
            src="https://yuxin-pms.oss-cn-beijing.aliyuncs.com/202106160906%282%29.mp4"
            class="video"
            autoplay
            loop
            muted
            preload
            x-webkit-airplay="true"
            airplay="allow"
            webkit-playsinline="true"
            playsinline="true"
          ></video>
          <div class="title">公寓管理系统数字生态引领者</div>
          <div class="subtitle">
            科技助推改革引擎
            <span style="padding: 0 8px"></span>匠心助力降本增效 <br />
            寓以致用<span style="padding: 0 4px"></span>信以为本
          </div>
        </div>
        <div class="banner4"></div>
        <div class="banner2">
          <div class="title_wra">
            <div class="title_left">
              <div style="font-size: 46px; margin-bottom: 40px">
                基于不同角色 <br />定制解决方案
              </div>
              <div style="line-height: 40px">
                租前 · 租中 · 租后<br />打通全维场景 信息协同高效
              </div>
            </div>
          </div>
        </div>
        <div class="banner3">
          <div class="title_wra">
            <div class="title_left">
              <div style="font-size: 46px; margin-bottom: 40px">
                头部市场服务经验 <br />顶级专家智库沉淀
              </div>
              <div style="line-height: 40px">
                全维一站式高效解决公寓管理难题 <br />定制化服务 · 使用更便捷 ·
                数据更安全
              </div>
              <a class="ty_btn" href="https://saas.yxpms.com">立即体验</a>
            </div>
            <div class="img-right">
              <img src="../assets/image/banner-3-1.png" alt="" />
            </div>
          </div>
        </div>
      </a-carousel>
      <div class="swiper-mobile">
        <a-carousel autoplay>
          <img src="../assets/image/mobile-banner1.png" alt="" />
          <img src="../assets/image/mobile-banner2.png" alt="" />
          <img src="../assets/image/mobile-banner3.png" alt="" />
        </a-carousel>
      </div>
    </div>
    <div class="solution">
      <h1>专注中大型公寓企业系统定制服务</h1>
      <p>
        响应国家政策，助力人才公寓、公租房、保障性租赁住房、民营公寓、地产商业公寓发展
      </p>
      <solution-item></solution-item>
      <div class="solution-box">
        <h1>一次投入永久使用 支持源码合作和二次开发</h1>
        <p>独立部署更省钱 数据私有更安全 源码合作更灵活</p>
        <solution-component></solution-component>
      </div>
    </div>
    <div class="product">
      <h1>全员体验更好的寓信PMS，提供资管全场景解决方案</h1>
      <p>各功能模块开发成熟，支持按需选择，高度灵活</p>
    </div>
    <div class="home-solution">
      <home-solution></home-solution>
    </div>
    <!-- <div class="product">
      <h1>支持不同终端 线上管理实时方便</h1>
      <p>打造智慧 高效 便捷的公寓管理系统</p>
    </div>
    <div class="shows">
      <div class="shows_con">
        <ul>
          <li
            :class="[current == item.key ? 'is_active' : '']"
            v-for="item in items"
            :key="item.key"
            @mouseenter="handleActive(item.key)"
            @click="handleClick(item.key)"
          >
            <h2>{{ item.title }}</h2>
            <h3>{{ item.subtitle }}</h3>
          </li>
        </ul>
        <a-tabs default-active-key="1" @change="callback" class="shows_tabs">
          <a-tab-pane key="1" tab="PMS管理系统"></a-tab-pane>
          <a-tab-pane key="2" tab="租客微信H5"></a-tab-pane>
          <a-tab-pane key="3" tab="租客小程序"></a-tab-pane>
          <a-tab-pane key="4" tab="PC独立官网"></a-tab-pane>
        </a-tabs>
        <div class="img_con" @click="handleClick(current)">
          <div v-if="current == 1">
            <span class="img_wra">
              <h2>大数据精准画像</h2>
              <h3>提供多重维度分析租客画像，精准提高企业租住服务</h3>
              <img src="../assets/image/img_01.png" class="half" alt="" />
            </span>
            <span class="img_wra">
              <h2>物联网智慧社区</h2>
              <h3>数字化管理房源</h3>
              <img src="../assets/image/img_02.png" class="half" alt="" />
            </span>
            <span class="img_wra">
              <h2>智能信息化平台</h2>
              <h3>
                信息化管理房源、财务、客源、智能硬件、高效对接【财务、业务、管理软件】
              </h3>
              <img
                src="../assets/image/img_03.png"
                class="full"
                height="390"
                alt=""
              />
            </span>
          </div>
          <div v-if="current == 2">
            <span class="img_wra">
              <h2>强大的品牌店铺</h2>
              <h3>可无缝嵌入公众号任何位置</h3>
              <img src="../assets/image/img_04.png" class="full" alt="" />
            </span>
            <span class="img_wra">
              <h2>移动展示平台</h2>
              <h3>一键转发 让房源在微信中自由传播</h3>
              <img src="../assets/image/img_05.png" class="full" alt="" />
            </span>
          </div>
          <div v-if="current == 3">
            <span class="img_wra">
              <h2>轻松找到房源入口</h2>
              <h3>给每位公寓服务者都配上专属小程序</h3>
              <img src="../assets/image/img_06.png" class="full" alt="" />
            </span>
            <span class="img_wra">
              <h2>创造优质租住体验</h2>
              <h3>用小程序构建租住服务闭环</h3>
              <img src="../assets/image/img_07.png" class="full" alt="" />
            </span>
          </div>
          <div v-if="current == 4">
            <span class="img_wra">
              <h2>自定义域名</h2>
              <h3>支持域名自定义 打造独立的品牌形象</h3>
              <img src="../assets/image/img_08.png" class="full" alt="" />
            </span>
            <span class="img_wra">
              <h2>提升品牌形象</h2>
              <h3>灵活装修设计 打造个性化页面</h3>
              <img src="../assets/image/img_09.png" class="full" alt="" />
            </span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="bi">
      <h1>BI数据大屏</h1>
      <p>
        打造清晰、直观、透明的公寓管理可视化平台，整合各项数据。实时监测入住率、查看代办事项和服务，对合同、资产进行全方面的管理，全面提升公寓的运营效率。
      </p>
      <a-carousel :arrows="mob">
        <div
          slot="prevArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="left: 10px; zindex: 1"
        >
          <a-icon type="left-circle" />
        </div>
        <div
          slot="nextArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="right: 10px"
        >
          <a-icon type="right-circle" />
        </div>
        <div v-for="(item, index) in biData" :key="index">
          <div class="bi-img">
            <img :src="item.src" alt="" @click="openBi(item.url)" />
          </div>
          <!-- <template v-if="index === 0">
            <div>
              <iframe id="iframe" :src="item.url"></iframe>
            </div>
          </template>
          <template v-else>
            <div class="bi-img">
              <img :src="item.src" alt="" @click="openBi(item.url)" />
            </div>
          </template> -->
        </div>
      </a-carousel>
    </div>
    <div class="device">
      <h1>构建智能化管理的物联网解决方案</h1>
      <p>深度整合业务场景，为各类房源提供安全合规管理支持</p>
      <div class="items">
        <div class="item" v-for="(item, index) in devices" :key="index">
          <div class="title">
            {{ item.title1
            }}<br
              v-if="
                screenWidth < 769 &&
                !['deviceLock', 'deviceMeter'].includes(item.value)
              "
            />{{ item.title2 }}
          </div>
          <div class="subtitle">
            {{ item.subtitle1 }}<br v-if="screenWidth < 769" />{{
              item.subtitle2
            }}
          </div>
          <img :src="item.src" @click="$router.push({ name: item.value })" />
        </div>
      </div>
    </div>
    <div class="about">
      <h1>关于寓信</h1>
      <p>科技助力居住行业发展</p>
      <div class="introduce-warp">
        <div class="video-warp">
          <video
            src="../assets/video/yuxin-info.mp4"
            class="video"
            loop
            muted
            autoplay
            preload
            controls="controls"
            x-webkit-airplay="true"
            airplay="allow"
            webkit-playsinline="true"
            playsinline="true"
          ></video>
        </div>
        <div class="mobile-warp">
          寓信科技，北京、深圳双总部并行，是一家专注于中大型公寓系统定制的科技互联网企业。业务领域涵盖保障性租赁住房、人才公寓、品牌公寓、园区宿舍以及政府监管平台等数字化解决方案。<br />

          服务客户包括中国银行、中粮集团、中海地产、中国联通、中兴发展、保利物业、太极股份、合景泰富、东方航空、格力集团、顺丰集团、上海新长宁、雄安集团、厦门地铁等央企国企及大型民营企业标杆客户。通过数百家头部企业的系统建设和运营经验沉淀，为企业构建一套完整的智慧公寓资产管理平台。
        </div>
      </div>
    </div>
    <div class="news">
      <div class="news_con">
        <h1>多维度服务案例 全场景智慧生态</h1>
        <!-- <p>满足广泛业务需求,全方位助力长租公寓企业数字化升级</p> -->
        <!-- <a-row type="flex" justify="space-between">
          <a-col v-for="i in list" :key="i.id" @click="goToDetail(i)">
            <img :src="i.linkAddress" width="345" height="208" alt="" />
            <div class="news_time">{{ i.releaseTime }}</div>
            <div class="news_info">{{ i.title }}</div>
          </a-col>
        </a-row> -->
        <service-case />
      </div>
    </div>
    <div class="partner">
      <div class="partner_con">
        <h1>点滴纪录 我们服务过的企业</h1>
        <service-enterprise :start="0" :end="6" />
        <service-enterprise :direction="2" :start="6" :end="12" />
        <service-enterprise :start="12" :end="18" />
        <service-enterprise :direction="2" :start="18" :end="24" />
        <service-enterprise :start="24" :end="30" />
        <service-enterprise :direction="2" :start="30" :end="36" />
        <service-enterprise :start="36" :end="42" />
        <img class="mask" src="@/assets/image/enterprise/mask.png" alt="" />
        <img
          class="mask right"
          src="@/assets/image/enterprise/mask.png"
          alt=""
        />
        <!--        <p>满足政府人才公寓、地产开发商、银行及商业资产整合者的不同场景业务需求</p>-->
        <!--        <vue-seamless-scroll-->
        <!--            :data="listData"-->
        <!--            :class-option="classOption"-->
        <!--            class="warp"-->
        <!--        >-->
        <!--          <div class="img_con">-->
        <!--            <img v-for="(item,index) in listData" :src="item.src" :key="index" alt="">-->
        <!--          </div>-->
        <!--        </vue-seamless-scroll>-->
        <!-- <div class="partner_logo">
          <div class="item_con" v-for="(item, index) in listData" :key="index">
            <img :src="item.src" alt="" />
            <p>{{ item.name }}</p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import SolutionComponent from '../components/SolutionComponent'
import SolutionItem from '../components/SolutionItem'
import homeSolution from '../components/homeSolution'
import { getNews } from '../api'
import ServiceCase from '@/components/ServiceCase.vue'
import ServiceEnterprise from '@/components/ServiceEnterprise.vue'
export default {
  name: 'HomePage',
  components: {
    SolutionComponent,
    SolutionItem,
    ServiceCase,
    ServiceEnterprise,
    homeSolution
    // vueSeamlessScroll
  },
  data () {
    return {
      current: 1,
      items: [
        {
          key: 1,
          title: 'PMS管理系统',
          subtitle: '全生态系统提升效能'
        },
        {
          key: 2,
          title: '租客微信H5',
          subtitle: '一键转发 让品牌自由传播'
        },
        {
          key: 3,
          title: '租客小程序',
          subtitle: '轻松找到房源入口'
        },
        {
          key: 4,
          title: 'PC端独立官网',
          subtitle: '打造独立的品牌形象'
        }
      ],
      listData: [
        {
          src: require('../assets/image/partner/logo_01.png'),
          name: '中国银行'
        },
        {
          src: require('../assets/image/partner/logo_02.png'),
          name: '中粮集团'
        },
        {
          src: require('../assets/image/partner/logo_03.png'),
          name: '中海地产'
        },
        {
          src: require('../assets/image/partner/logo_21.png'),
          name: '中国联通'
        },
        {
          src: require('../assets/image/partner/logo_20.png'),
          name: '中国雄安集团'
        },
        {
          src: require('../assets/image/partner/logo_05.png'),
          name: '合景泰富'
        },
        {
          src: require('../assets/image/partner/logo_04.png'),
          name: '龙湖冠寓'
        },
        {
          src: require('../assets/image/partner/logo_23.png'),
          name: '紫金和寓'
        },
        {
          src: require('../assets/image/partner/logo_06.png'),
          name: '金地物业'
        },
        {
          src: require('../assets/image/partner/logo_07.png'),
          name: '东龙控股'
        },
        {
          src: require('../assets/image/partner/logo_24.png'),
          name: '顺丰速运'
        },
        {
          src: require('../assets/image/partner/logo_16.png'),
          name: '太极计算机'
        },
        {
          src: require('../assets/image/partner/logo_25.png'),
          name: '格力集团'
        },
        {
          src: require('../assets/image/partner/logo_09.png'),
          name: '新沂城投'
        },
        {
          src: require('../assets/image/partner/logo_17.png'),
          name: '亚新物业'
        },
        {
          src: require('../assets/image/partner/logo_27.png'),
          name: '兴海物联'
        },
        {
          src: require('../assets/image/partner/logo_22.png'),
          name: '联发集团'
        },
        {
          src: require('../assets/image/partner/logo_26.png'),
          name: '诚志海图'
        },
        {
          src: require('../assets/image/partner/logo_18.png'),
          name: '邦代物业'
        },
        {
          src: require('../assets/image/partner/logo_19.png'),
          name: '保定人才公寓'
        },
        {
          src: require('../assets/image/partner/logo_08.png'),
          name: '康桥集团'
        }
        // {
        //   src: require('../assets/image/partner/logo_11.png'),
        //   name: '海尔集团',
        // },
        // {
        //   src: require('../assets/image/partner/logo_12.png'),
        //   name: '像素公寓',
        // },
        // {
        //   src: require('../assets/image/partner/logo_13.png'),
        //   name: '宽寓集团',
        // },
        // {
        //   src: require('../assets/image/partner/logo_14.png'),
        //   name: '庭苑公寓',
        // }
      ],
      classOption: {
        limitMoveNum: 2,
        direction: 3
      },
      list: [],
      // biData: [
      //   require('../assets/image/bi1.png'),
      //   require('../assets/image/bi2.png'),
      //   require('../assets/image/bi3.png')
      // ],
      biData: [],
      mob: false,
      devices: [
        {
          title1: '智能',
          title2: '门锁',
          subtitle1: '安全便捷，远程控制，',
          subtitle2: '多种认证',
          src: require('../assets/image/device/lock.png'),
          value: 'deviceLock'
        },
        {
          title1: '智能',
          title2: '水电表',
          subtitle1: '实时监测，',
          subtitle2: '精准计量',
          src: require('../assets/image/device/meter.png'),
          value: 'deviceMeter'
        },
        {
          title1: '智能',
          title2: '门禁',
          subtitle1: '掌控安全，',
          subtitle2: '畅享便捷生活',
          src: require('../assets/image/device/access.png'),
          value: 'deviceAccess'
        },
        {
          title1: '车辆/人行',
          title2: '道闸',
          subtitle1: '智能化管控，',
          subtitle2: '便捷通行',
          src: require('../assets/image/device/gate.png'),
          value: 'deviceGate'
        },
        {
          title1: '烟雾/燃气',
          title2: '报警器',
          subtitle1: '全方位监测，',
          subtitle2: '及时预警',
          src: require('../assets/image/device/alarm.png'),
          value: 'deviceAlarm'
        }
      ],
      screenWidth: document.body.clientWidth
    }
  },
  methods: {
    goToDetail (i) {
      //linkType=2，是外链地址
      if (i.linkType == 2) {
        window.open(i.content)
      } else {
        this.$router.push({ name: 'details', params: { id: i.id } })
      }
    },
    handleActive (key) {
      this.current = key
    },
    handleClick (key) {
      this.$router.push({ name: 'product', params: { key: key } })
    },
    callback (key) {
      this.current = key
    },
    async getList () {
      const params = {
        pageSize: 3,
        pageNo: 1,
        status: 1
      }
      const res = await getNews(params)
      if (res.data.status.code == 200) {
        this.list = res.data.result
      }
    },
    biInit () {
      const ids = [
        '',
        'F0c5',
        'C0c5',
        'B0c5',
        'A0c5',
        'z0c5',
        'y0c5',
        'x0c5',
        'w0c5'
      ]
      for (let i = 2; i < 9; i++) {
        this.biData.push({
          url: `https://biconfig.yxpms.com/#/third/${ids[i]}`,
          src: require(`../assets/image/bi/${i}.png`)
        })
      }
    },
    openBi (value) {
      window.open(value)
    },
    getWidth () {
      const that = this
      window.onresize = () => {
        return (() => {
          window.screenWidth = document.body.clientWidth
          that.screenWidth = window.screenWidth
        })()
      }
    }
  },
  created () {
    if (document.body.clientWidth > 768) {
      this.listData.splice(this.listData.length - 1, 1)
      this.mob = true
    }
    this.getList()
    this.biInit()
    this.getWidth()
  },
  mounted () {
    console.log('iframe', document.getElementById('iframe'))
  }
}
</script>

<style lang="less" scoped>
.video_box {
  height: 760px;
  width: 100%;
  position: relative;
  overflow: hidden;
  .video_wra {
    width: 100%;
    height: 760px;
    overflow: hidden;
    position: relative;
  }
  .video {
    width: 1920px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -960px;
    z-index: 1;
    filter: brightness(60%);
  }
  .title {
    width: 100%;
    color: #fff;
    font-size: 46px;
    text-align: center;
    position: absolute;
    top: 40%;
    left: 0;
    z-index: 100;
    letter-spacing: 10px;
  }
  .subtitle {
    width: 100%;
    color: #fff;
    font-size: 20px;
    position: absolute;
    top: 52%;
    left: 0;
    text-align: center;
    z-index: 100;
    line-height: 40px;
  }
  .ty_btn {
    width: 150px;
    height: 40px;
    line-height: 40px;
    background-color: #fff;
    color: #1b55e9;
    font-size: 20px;
    border-radius: 20px;
    text-align: center;
    margin-top: 20px;
  }
  .title_wra {
    width: 1400px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    .title_left {
      position: relative;
      width: 550px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 20px;
    }
    .img-right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      img {
        width: 80%;
      }
    }
  }
  .banner2 {
    width: 100%;
    height: 760px;
    background: url('../assets/image/banner-2.png') no-repeat center;
  }

  .banner4 {
    width: 100%;
    height: 760px;
    background: url('../assets/image/banner-4.jpg') no-repeat center;
  }
  .banner3 {
    .banner2;
    background: url('../assets/image/banner-3.png') no-repeat center;
  }
  .swiper-mobile {
    display: none;
  }
}
.solution {
  .solution-box {
    background-color: #f5f7fa;
    margin-top: 80px;
    padding-bottom: 80px;
  }
  h1 {
    padding-top: 60px;
    text-align: center;
    color: #0d2a42;
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.59);
    margin-bottom: 2em;
  }
}
.product {
  text-align: center;
  margin-bottom: 20px;
  h1 {
    padding-top: 60px;
    text-align: center;
    color: #0d2a42;
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.59);
    margin-bottom: 2em;
  }
}
.shows {
  height: 780px;
  background-color: #1b1f26;
  .shows_con {
    position: relative;
    width: 1440px;
    margin: 0 auto;
    ul {
      padding-left: 100px;
      width: 360px;
      overflow: hidden;
      li {
        list-style: none;
        position: relative;
        padding: 0 30px;
        margin-top: 100px;
        cursor: pointer;
        transition: all 0.3s linear;
        &:before {
          display: block;
          content: '';
          width: 6px;
          height: 60px;
          background-color: transparent;
          position: absolute;
          top: 0;
          left: 0;
        }
        h2 {
          color: #76797c;
          font-size: 20px;
        }
        h3 {
          color: #76797c;
          font-size: 16px;
        }
      }
      .is_active {
        transform: translateX(20px);
        h2,
        h3 {
          color: #fff;
        }
        &:before {
          background-color: #0167ff;
        }
      }
    }
    .shows_tabs {
      display: none;
    }
    .img_con {
      width: 950px;
      position: absolute;
      right: 0;
      top: 0;
      .img_wra {
        display: inline-block;
        position: relative;
        cursor: pointer;
        .half {
          width: 475px;
        }
        .full {
          width: 950px;
        }
        h2 {
          position: absolute;
          left: 20px;
          bottom: 30px;
          color: #fff;
          font-size: 24px;
          transition: all 0.3s linear;
        }
        h3 {
          position: absolute;
          left: 20px;
          bottom: 0;
          color: #fff;
          font-size: 16px;
          opacity: 0;
          transition: all 0.3s linear;
        }
        &:hover {
          h2,
          h3 {
            transform: translateY(-40px);
          }
          h3 {
            opacity: 1;
          }
        }
      }
    }
  }
}
.bi {
  text-align: center;
  h1 {
    padding-top: 60px;
  }
  p {
    width: 750px;
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.59);
    margin: 0 auto 2em;
  }
  .ant-carousel {
    font-size: 0;
    width: 1200px;
    margin: 0 auto;
  }
  .bi-img {
    // background-color: #1B1F26;
    img {
      width: 1200px;
      height: auto;
      vertical-align: top;
      cursor: pointer;
      // width: 100%;
      // height: 600px;
      // object-fit: contain;
    }
  }
  iframe {
    width: 1200px;
    min-height: 680px;
    border: none;
  }
}
.device {
  width: 1200px;
  margin: 0 auto;
  text-align: center;
  h1 {
    margin: 60px 0 0;
    font-size: 28px;
    font-weight: 600;
  }
  p {
    margin: 4px 0 0;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.59);
  }
  .items {
    margin-top: 36px;
    display: flex;
    flex-wrap: wrap;
    .item {
      flex: 1;
      background-color: #f1f5fd;
      padding: 20px;
      cursor: pointer;
      .title {
        font-size: 20px !important;
        font-weight: 700;
        margin-top: 12px;
      }
      .subtitle {
        font-size: 14px !important;
        color: #607499;
        margin-top: 6px;
      }
      img {
        margin-top: 46px;
        width: 100%;
        user-select: none;
      }
      &:hover {
        transform: scale(1.01);
        transition: all 0.2s linear;
        box-shadow: 0 0 15px 0 rgba(157, 178, 222, 0.7);
      }
    }
    .item + .item {
      margin-left: 4px;
    }
  }
}
.about {
  width: 1440px;
  height: 554px;
  margin: 48px auto 0;
  padding: 80px 120px;
  text-align: center;
  background: url('../assets/image/home/bj-about.png') no-repeat;
  background-size: cover;

  h1 {
    margin: 0;
    font-size: 28px;
    font-weight: 600;
  }
  p {
    margin: 4px 0 0;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.59);
  }
  .introduce-warp {
    display: flex;
    justify-content: space-between;
    margin-top: 31px;
  }
  .video-warp {
    margin-right: 34px;

    .video {
      width: 480px;
      height: 270px;
    }
  }
  .mobile-warp {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    color: #030d1e;
    line-height: 36px;
    text-align: justify;
  }
}

.news {
  .news_con {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 40px;
    overflow: hidden;
    // .ant-col {
    //   cursor: pointer;
    //   img {
    //     transition: all 0.3s ease;
    //   }
    //   &:hover {
    //     img {
    //       box-shadow: 0 4px 10px 2px rgba(48, 48, 48, 0.3);
    //     }
    //     .news_info {
    //       color: #0087fd;
    //     }
    //   }
    // }
    h1 {
      padding-top: 60px;
      text-align: center;
      color: #0d2a42;
      margin-bottom: 35px;
    }
    // p {
    //   font-size: 16px;
    //   text-align: center;
    //   color: rgba(0, 0, 0, 0.59);
    //   margin-bottom: 2em;
    // }
    // .news_time {
    //   margin-top: 10px;
    //   color: #555;
    // }
    // .news_info {
    //   color: #333;
    //   width: 345px;
    //   margin: 0 auto;
    //   white-space: nowrap;
    //   text-overflow: ellipsis;
    //   overflow: hidden;
    //   transition: color 0.3s ease;
    // }
  }
}
.partner {
  // background-color: #f5f7fa;
  padding-top: 50px;
  .partner_con {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 50px;
    overflow: hidden;
    position: relative;
    .mask {
      position: absolute;
      top: 0;
      width: 8%;
      height: 100%;
      object-fit: cover;
    }
    .right {
      right: 0;
      transform: rotate(180deg);
    }
    h1 {
      text-align: center;
      color: #0d2a42;
      margin-bottom: 35px;
    }
    p {
      font-size: 16px;
      text-align: center;
      color: rgba(0, 0, 0, 0.59);
      margin-bottom: 2em;
    }
  }
  .warp {
    width: 1200px;
    height: 120px;
    margin: 0 auto;
    overflow: hidden;
    .img_con {
      display: flex;
      img {
        width: 220px;
        margin-right: 20px;
      }
    }
  }

  .item_con {
    display: inline-block;
    width: 20%;
    padding: 0 10px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 105px;
    }
    p {
      margin-top: 10px;
    }
  }
}

.ant-carousel /deep/ .slick-slide {
  text-align: center;
  // background: #364d79;
  overflow: hidden;
}

.ant-carousel /deep/ .custom-slick-arrow {
  width: 40px;
  height: 40px;
  font-size: 40px;
  color: #fff;
  opacity: 0.3;
}
.ant-carousel /deep/ .custom-slick-arrow:before {
  display: none;
}
.ant-carousel /deep/ .custom-slick-arrow:hover {
  opacity: 0.7;
}
/deep/ .ant-carousel .slick-dots-bottom {
  bottom: 30px;
  li {
    margin: 0 5px;
    button {
      height: 6px;
      border-radius: 3px;
    }
  }
}

@media screen and (max-width: 768px) {
  .page {
    width: 100vw;
    overflow: hidden;
  }
  h1 {
    font-size: 26px !important;
  }
  p {
    font-size: 12px !important;
  }
  .title {
    font-size: 20px !important;
  }
  .subtitle {
    font-size: 16px !important;
    top: 45% !important;
  }
  .banner2,
  .banner3 {
    display: none;
  }
  .video_box {
    height: auto;
    .swiper-mobile {
      display: block;
      img {
        width: 100%;
      }
      /deep/ .ant-carousel .slick-dots-bottom {
        bottom: 10px;
      }
    }
    .swiper-pc {
      display: none;
    }
  }
  .shows {
    height: 100vw;
    .shows_con {
      width: 100vw;
      ul {
        display: none;
      }
      .shows_tabs {
        margin: 0 auto;
        padding-top: 10px;
        display: block;
        color: #fff;
        /deep/ .ant-tabs-bar {
          border-bottom: none;
        }
        /deep/ .ant-tabs-tab {
          margin: 0 !important;
          padding: 12px 0 !important;
          width: 25vw;
          text-align: center;
        }
      }
      .img_con {
        width: 100vw;
        top: unset;
        .img_wra {
          .half {
            width: 50vw;
          }
          .full {
            width: 100vw;
            height: auto;
          }
          h2 {
            font-size: 16px;
            transition: none;
          }
          h3 {
            display: none;
          }
          &:hover {
            h2,
            h3 {
              transform: none;
            }
          }
        }
      }
    }
  }
  .bi {
    p {
      width: 90vw !important;
    }
    .ant-carousel {
      width: 100%;
    }
    .bi-img {
      img {
        width: 100%;
        // height: 300px;
      }
    }
    iframe {
      width: 100%;
      min-height: 432px;
    }
  }
  .news_con {
    width: 100vw !important;
    // .ant-col {
    //   width: 100%;
    //   text-align: center;
    // }
  }
  .device {
    width: auto;
    .items {
      margin: 36px 2px 0;
      .item {
        flex: initial;
        width: calc(33.33% - 4px);
        margin: 2px !important;
        padding: 8px 2px;
        &:first-child {
          width: calc(66.66% - 4px);
        }
        .title {
          font-size: 16px !important;
        }
        img {
          margin-top: 16px;
          width: auto;
          height: 100px;
        }
      }
    }
  }
  .partner {
    .partner_con {
      width: 100vw;
      .warp {
        display: none;
      }
      .item_con {
        width: 33.3%;
        img {
          height: auto;
        }
      }
    }
  }
  .about {
    width: 100%;
    height: 550px;
    margin: 0 auto 30px;
    padding: 30px 15px;
    text-align: center;
    background: none;
    h1 {
      margin: 30px 0 0;
      font-size: 28px;
      font-weight: 600;
    }
    p {
      margin: 4px 0 0;
      font-size: 16px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.59);
    }
    .introduce-warp {
      display: block;
      justify-content: space-between;
      margin-top: 31px;
    }
    .video-warp {
      width: 100%;
      margin-bottom: 16px;
      height: 198px;
      border-radius: 0px;
      .video {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }
    .mobile-warp {
      color: #030d1e;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: left;
      text-align: justify;
    }
  }
}
.home-solution {
  width: 1200px;
  margin: 0 auto;
}
@media screen and (max-width: 420px) {
  .bi {
    iframe {
      height: 100vh;
      // min-height: 227px;
    }
  }
  .home-solution {
    // display: none;
  }
}
</style>
