<template>
  <vue-seamless-scroll
    :data="data"
    :class-option="classOption"
    class="service-enterprise"
  >
    <div class="item" v-for="(item, index) in data" :key="index">
      <img :src="item" alt="" />
    </div>
  </vue-seamless-scroll>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
  name: "ServiceEnterprise",
  components: {
    vueSeamlessScroll,
  },
  props: {
    direction: {
      type: Number,
      default: 3,
    },
    start: {
      type: Number,
      default: 0,
    },
    end: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      data: [],
      classOption: {
        direction: this.direction,
      },
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      for (let i = 1; i < 43; i++) {
        this.data.push(require(`@/assets/image/enterprise/${i}.png`));
      }
      this.data = this.data.slice(this.start, this.end);
    },
  },
};
</script>

<style lang="less" scoped>
.service-enterprise {
  width: 210px * 8;
  // padding: 10px 0;
  .item {
    width: 180px;
    margin: 10px 20px 10px 10px;
    display: inline-block;
    box-shadow: 0 5px 10px rgba(26, 28, 33, 10%);
    border-radius: 8px;
    img {
      width: 100%;
    }
  }
}
@media screen and (max-width: 768px) {
  .service-enterprise {
    width: 180px * 8;
    .item {
      width: 150px;
    }
  }
}
</style>
