<template>
  <vue-seamless-scroll :data="data" :class-option="optionLeft" class="service-case">
    <div class="item" v-for="(item, index) in data" :key="index">
      <a :href="item.content" target="_blank">
        <img :src="item.linkAddress" alt="" class="bg">
        <div class="logo">
          <img :src="item.logo" alt="">
        </div>
        <div class="desc">
          <p>{{item.title}}{{item.subtitle && `：${item.subtitle}`}}</p>
        </div>
      </a>
    </div>
  </vue-seamless-scroll>
</template>

<script>
import { getNews } from '@/api'
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  name: 'ServiceCase',
  components: {
    vueSeamlessScroll
  },
  computed: {
    optionLeft () {
      return {
        direction: 2
      }
    }
  },
  data() {
    return {
      data: [
        require('@/assets/image/partner/logo_21.png'),
        require('@/assets/image/partner/logo_01.png'),
        require('@/assets/image/partner/logo_02.png'),
        require('@/assets/image/partner/logo_03.png'),
        require('@/assets/image/partner/logo_05.png'),
        require('@/assets/image/partner/logo_07.png'),
        require('@/assets/image/partner/logo_28.png')
      ]
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      const params = {
        status: 1,
        type: 1
      }
      const res = await getNews(params)
      if (res.data.status.code === '200') {
        let data = res.data.result.map((item, index) => {
          item.logo = this.data[index]
          return item
        })
        this.data = data
      }
    }
  }
}
</script>

<style lang='less' scoped>
.service-case {
  width: 440px * 7;
  .item {
    width: 400px;
    overflow: hidden;
    background: #FFFFFF;
    border-radius: 8px;
    margin: 10px 30px 10px 10px;
    box-shadow: 0 0 5px #EAEEF9;
    display: inline-block;
    position: relative;
    a {
      display: block;
    }
    .bg {
      width: 100%;
      height: 200px;
      transition: all 0.5s ease-in-out;
    }
    .logo {
      position: absolute;
      top: 150px;
      left: 30px;
      width: 90px;
      height: 90px;
      line-height: 90px;
      border-radius: 100%;
      box-shadow: 0 4px 10px #DDDDDD;
      background: #FFFFFF;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    .desc {
      padding: 60px 30px 30px;
      p {
        margin: 0;
        height: 60px;
        font-size: 20px;
        color: #333333;
      }
    }
    &:hover {
      box-shadow: 5px 5px 15px #CCCCCC;
      .bg {
        transform: scale(1.05);
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .service-case {
    width: 270px * 7;
    .item {
      width: 240px;
      margin: 10px 20px 10px 10px;
      .bg {
        height: 120px;
      }
      .logo {
        top: 90px;
        left: 20px;
        width: 60px;
        height: 60px;
        line-height: 60px;
      }
      .desc {
        padding: 40px 20px 20px;
        p {
          height: 72px;
          font-size: 16px;
        }
      }
    }
  }
}
</style>