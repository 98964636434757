<template>
  <div class="container">
    <div class="tab-warp">
      <div
        :class="['tab-item', selectIndex == index ? 'selected' : '']"
        v-for="(item, index) in list"
        :key="index"
        @click="selectIndex = index"
      >
        {{ item }}
      </div>
    </div>
    <div class="content" v-if="selectIndex == 0">
      <a-row type="flex" class="flex-warp">
        <a-col class="left-text">
          <h3>租控可视化解决方案</h3>
          <p>
            通过列表、房态图等形式直观展示所有房源各维度信息，可手动自由组合管理，全面掌控房源状态与运营指标，结构化展示所有房源基础信息、出租状态、租赁周期等情况，直观可视；同时可以根据业务需求快速定位目标房源以及穿透租控详情，简单高效
          </p>
          <h4>功能亮点</h4>
          <div class="lightspot">
            <a-icon type="check" style="color: #3c79e5; font-weight: bold" />
            支持集中式及分散式房源
          </div>
          <div class="lightspot">
            <a-icon type="check" style="color: #3c79e5; font-weight: bold" />
            支持合租、整租房源状态互转
          </div>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button button-size">免费试用</div>
          </a>
        </a-col>
        <a-col class="mobile-mar">
          <img
            src="../assets/image/ProductApartment/house-show.png"
            class="show-pic2"
            alt=""
            srcset=""
          />
        </a-col>
      </a-row>
    </div>
    <div class="content" v-if="selectIndex == 1">
      <a-row type="flex" class="flex-warp">
        <a-col class="left-text">
          <h3>全生态电子合同服务</h3>
          <p>
            多重认证更安全，灵活的认证方式，支持手机号、银行卡、OCR识别等多种技术认证方式，满足不同场景认证。依照公寓行业定制电子合同模板，实现新签、转租、续租、退租等全流程电子化，安全存储，步步留痕。
          </p>
          <h4>功能亮点</h4>
          <div class="lightspot">
            <a-icon type="check" style="color: #3c79e5; font-weight: bold" />
            合同到期智能预警
          </div>
          <div class="lightspot">
            <a-icon type="check" style="color: #3c79e5; font-weight: bold" />
            合同流程在线提交、审核
          </div>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button button-size">免费试用</div>
          </a>
        </a-col>
        <a-col class="img-warp">
          <img
            src="../assets/image/home/solution2-1.png"
            class="img-2-1"
            alt=""
            srcset=""
          />
          <img
            src="../assets/image/home/solution2-2.png"
            class="img-2-2"
            alt=""
            srcset=""
          />
        </a-col>
      </a-row>
    </div>
    <div class="content" v-if="selectIndex == 2">
      <a-row type="flex" class="flex-warp">
        <a-col class="left-text">
          <h3>业财一体化对接</h3>
          <p>
            根据租约信息自动生成收支计划表、账单、财务凭证等，支持与三方平台（金蝶、用友、SAP等财务系统）对接，实现财务核销自动化，打造合同签订-
            租金收取- 现金流测算的全流程资管闭环。
          </p>
          <h4>功能亮点</h4>
          <div class="lightspot">
            <a-icon type="check" style="color: #3c79e5; font-weight: bold" />
            电子财务凭证智能存档、步步留痕
          </div>
          <div class="lightspot">
            <a-icon type="check" style="color: #3c79e5; font-weight: bold" />
            租金账单自动生成、一键同步
          </div>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button button-size">免费试用</div>
          </a>
        </a-col>
        <a-col class="img-warp">
          <img
            src="../assets/image/home/solution3-1.png"
            class="img-3-1"
            alt=""
            srcset=""
          />
          <img
            src="../assets/image/home/solution3-2.png"
            class="img-3-2"
            alt=""
            srcset=""
          />
        </a-col>
      </a-row>
    </div>
    <div class="content" v-if="selectIndex == 3">
      <a-row type="flex" class="flex-warp">
        <a-col class="left-text">
          <h3>自定义经营数据看板</h3>
          <p>
            提供资产收益管理数十项核心数据分析，用BI系统支撑经营，提升收益数据实时采集，实时分析，保障数据时效性、准确性可按照自身经营思路，自定义数据看板，灵活高效完成数据洞察
          </p>
          <h4>功能亮点</h4>
          <div class="lightspot">
            <a-icon type="check" style="color: #3c79e5; font-weight: bold" />
            实时监测租金收益与流失
          </div>
          <div class="lightspot">
            <a-icon type="check" style="color: #3c79e5; font-weight: bold" />
            提前把控利润流失风险
          </div>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button button-size">免费试用</div>
          </a>
        </a-col>
        <a-col class="mobile-mar">
          <img
            src="../assets/image/home/solution4.png"
            class="show-pic"
            alt=""
            srcset=""
          />
        </a-col>
      </a-row>
    </div>
    <div class="content" v-if="selectIndex == 4">
      <a-row type="flex" class="flex-warp">
        <a-col class="left-text">
          <h3>集成智慧IoT平台</h3>
          <p>
            智能硬件设备集成，通过微服务+容器化底层架构，轻松接入智能门锁、水电表、智慧停车、能耗、门禁巡更巡检、环境监测、消防、智能家居家电等智能设备，实现一个平台万物互联。
          </p>
          <h4>功能亮点</h4>
          <div class="lightspot">
            <a-icon type="check" style="color: #3c79e5; font-weight: bold" />
            一键远程批量抄表
          </div>
          <div class="lightspot">
            <a-icon type="check" style="color: #3c79e5; font-weight: bold" />
            水电费、公摊费自动计算
          </div>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button button-size">免费试用</div>
          </a>
        </a-col>
        <a-col class="mobile-mar">
          <img
            src="../assets/image/home/solution5.png"
            class="show-pic"
            alt=""
            srcset=""
          />
        </a-col>
      </a-row>
    </div>
    <div class="content" v-if="selectIndex == 5">
      <a-row type="flex" class="flex-warp">
        <a-col class="left-text">
          <h3>租后服务在线化</h3>
          <p>
            租客可在线提交报事报修、保洁预约、投诉建议等工单，物业服务人员通过工人后台及时处理，并及时反馈工单进程，提升物业效率与租客服务体验。也可自助查看合同详情及支付查看相关租金、水电、物业费等账单明细。
          </p>
          <h4>功能亮点</h4>
          <div class="lightspot">
            <a-icon type="check" style="color: #3c79e5; font-weight: bold" />
            门店租后服务评价体系
          </div>
          <div class="lightspot">
            <a-icon type="check" style="color: #3c79e5; font-weight: bold" />
            门店增值业务服务
          </div>
          <a
            href="https://wenjuan.feishu.cn/m?t=sBAsngER5uBi-d97s"
            target="_blank"
          >
            <div class="button button-size">免费试用</div>
          </a>
        </a-col>
        <a-col class="mobile-pic2-mar">
          <img
            src="../assets/image/home/solution6.png"
            class="show-pic"
            alt=""
            srcset=""
          />
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "homeSolution",
  props: {},
  data() {
    return {
      selectIndex: 0,
      list: [
        "房源管理",
        "合同管理",
        "业财一体",
        "经营看板",
        "智能设备",
        "租后服务",
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.button {
  cursor: pointer;
  background: #2267f6;
  border-radius: 2px;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plain {
  border: 2px solid #0177ff;
  background-color: transparent;
  color: #0177ff;
  border-radius: 2px;
}
.container {
  width: 1172px;
  height: 580px;
  border: 1px solid #134de8;
  .tab-warp {
    width: 100%;
    display: flex;
    align-items: center;

    .tab-item {
      cursor: pointer;
      flex: 1;
      height: 56px;
      line-height: 56px;
      text-align: center;
      background: #f0f5fe;
      font-size: 20px;
      font-weight: bold;
      color: #3d3d3d;
    }
    .selected {
      background: #ffffff;
      position: relative;
    }
    .selected::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: #0069ea;
    }
  }
  .content {
    padding: 32px;
    .img-warp {
      flex: 1;
      position: relative;
    }
    .flex-warp {
      flex-wrap: nowrap;
    }
    .left-text {
      width: 326px;
      margin-right: 43px;
      margin-top: 20px;
      h3 {
        font-size: 20px;
        font-weight: bold;
      }
      h4 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 16px;
      }
      p {
        font-size: 14px;
        // font-weight: bold;
        line-height: 2;
      }
      .lightspot {
        width: 328px;
        height: 47px;
        line-height: 47px;
        padding-left: 16px;
        background: #f0f5fe;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .button-size {
        width: 100%;
        height: 47px;
        margin-top: 40px;
      }
    }
    .show-pic {
      width: 740px;
      // height: 414px;
    }
    .show-pic2 {
      width: 720px;
      // height: 440px;
    }
  }
  .img-2-1 {
    width: 620px;
    // height: 311px;
    position: absolute;
    left: 0;
    top: -20px;
  }
  .img-2-2 {
    width: 620px;
    // height: 320px;
    position: absolute;
    right: 0;
    bottom: -40px;
    // z-index: 2;
  }
  .img-3-1 {
    width: 600px;
    // height: 315px;
    position: absolute;
    left: 0;
    bottom: -40px;
  }
  .img-3-2 {
    width: 600px;
    // height: 331px;
    position: absolute;
    right: 0;
    top: -20px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 0 20px;
    width: calc(100vw - 40px);
    height: auto;
    .tab-warp {
      .tab-item {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        padding: 0 10px;
        line-height: inherit;
      }
    }
    .content {
      padding: 12px;
      .flex-warp {
        flex-wrap: wrap;
      }
      .left-text {
        width: 100%;
        margin: 0;
        h3 {
          text-align: center;
          font-size: 16px;
        }
        h4 {
          font-size: 16px;
        }
        p {
          font-size: 14px;
          font-weight: 400;
        }
        .lightspot {
          width: 100%;
          height: 36px;
          line-height: 36px;
          font-size: 14px;
        }
        .button-size {
          height: 36px;
          margin-top: 24px;
        }
      }
      .mobile-mar {
        margin: 20px;
      }
      .mobile-pic2-mar {
        margin-top: 20px;
      }
      .show-pic {
        width: 100%;
        height: auto;
      }
      .show-pic2 {
        width: 100%;
        height: auto;
      }
      .img-warp {
        flex: auto;
        margin: 20px auto;
        max-width: 320px;
        width: 100%;
        height: 170px;
      }
      .img-2-1 {
        max-width: 250px;
        width: 100%;
        height: auto;
      }
      .img-2-2 {
        max-width: 250px;
        width: 100%;
        height: auto;
      }
      .img-3-1 {
        max-width: 250px;
        width: 100%;
        height: auto;
      }
      .img-3-2 {
        max-width: 250px;
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
